import React, { FC, PropsWithChildren, useEffect } from 'react'

import { useDispatch } from 'react-redux'

import { fetchBannersAvailableAction } from 'actions/banner/bannerAction'
import { openEmojiPickerAction } from 'actions/emoji/openEmojiPickerAction'
import { toggleOpenContactsAction } from 'actions/messenger/toggleOpenContactsAction'
import { hideTooltipsAction } from 'actions/tooltipAction'
import { fetchGdprStatusAction } from 'actions/user/fetchGdprStatusAction'
import { useShallowEqualSelector } from 'common/hooks/useShallowEqualSelector'
import { LOCALES_REGEXP } from 'common-constants/locale'
import { WaveLayout } from 'components/designSystem/layout/WaveLayout/WaveLayout'
import { InternalContent } from 'components/layout/MambaLayout/InternalContent'
import { LocaleSwitch } from 'components/system/LocaleSwitch'
import { useHasUserRestrictions } from 'functions/hasUserRestrictions'
import { useReducersInsert } from 'hooks/useReducersInsert'
import { bannerReducer } from 'reducers/banner/bannerReducer'
import { settingsReducer } from 'reducers/settings/settingsReducer'
import { createModalRoutes } from 'routes/layoutModalRoutes'

import {
  LayoutContext,
  useLayoutContextValue,
} from './MambaLayoutIndex.context'

const MambaLayoutIndex: FC<
  PropsWithChildren & {
    redesignedPath?: boolean
    redesignLayoutPath?: boolean
  }
> = ({ children, redesignedPath, redesignLayoutPath }) => {
  useReducersInsert({ bannerReducer, settingsReducer: settingsReducer })
  const dispatch = useDispatch()
  const {
    authorized,
    basePath,
  } = useShallowEqualSelector(
    ({
      authorizationReducer: { authorized },
      systemReducer: { basePath },
    }) => ({ authorized, basePath })
  )

  const hasUserRestrictions = useHasUserRestrictions()

  useEffect(() => {
    if (!hasUserRestrictions) {
      dispatch(fetchBannersAvailableAction())
      dispatch(fetchGdprStatusAction())
    }
  }, [dispatch, hasUserRestrictions, authorized])

  const contextValue = useLayoutContextValue()

  const handleCloseClick = () => {
    dispatch(hideTooltipsAction())
    dispatch(openEmojiPickerAction(false))
    dispatch(toggleOpenContactsAction(false))
  }

  const modalRoutes = createModalRoutes(authorized)

  /**
   * https://redmine.mamba.ru/issues/119930
   * В момент авторизаций с url /ru/login/ и после удаление locale из url.
   * Путем RouteSwitch.tsx:105 в basePath остается /ru.
   * Из-за этого уже собранные модальные url не попадают под правила.
   * Поэтому нужно удалить locale из basePath
   */
  const _basePath = authorized
    ? basePath.replace(`:locale(${LOCALES_REGEXP})`, '')
    : basePath

  const modal = <LocaleSwitch matchPath={_basePath} routes={modalRoutes} />

  if (redesignedPath) {
    return (
      <LayoutContext.Provider value={contextValue}>
        {children}
        {modal}
      </LayoutContext.Provider>
    )
  } else if (redesignLayoutPath) {
    return (
      <LayoutContext.Provider value={contextValue}>
        <WaveLayout modal={modal} onClick={handleCloseClick}>
          {children}
        </WaveLayout>
      </LayoutContext.Provider>
    )
  } else {
    return (
      <LayoutContext.Provider value={contextValue}>
        <InternalContent modal={modal} onClick={handleCloseClick}>
          {children}
        </InternalContent>
      </LayoutContext.Provider>
    )
  }
}

export default MambaLayoutIndex
