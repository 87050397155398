import svg1 from 'components/block/ControlButtonsBar/assets/button/double/background/1.svg'
import svg10 from 'components/block/ControlButtonsBar/assets/button/double/background/10.svg'
import svg11 from 'components/block/ControlButtonsBar/assets/button/double/background/11.svg'
import svg12 from 'components/block/ControlButtonsBar/assets/button/double/background/12.svg'
import svg13 from 'components/block/ControlButtonsBar/assets/button/double/background/13.svg'
import svg14 from 'components/block/ControlButtonsBar/assets/button/double/background/14.svg'
import svg15 from 'components/block/ControlButtonsBar/assets/button/double/background/15.svg'
import svg16 from 'components/block/ControlButtonsBar/assets/button/double/background/16.svg'
import svg17 from 'components/block/ControlButtonsBar/assets/button/double/background/17.svg'
import svg18 from 'components/block/ControlButtonsBar/assets/button/double/background/18.svg'
import svg19 from 'components/block/ControlButtonsBar/assets/button/double/background/19.svg'
import svg2 from 'components/block/ControlButtonsBar/assets/button/double/background/2.svg'
import svg20 from 'components/block/ControlButtonsBar/assets/button/double/background/20.svg'
import svg21 from 'components/block/ControlButtonsBar/assets/button/double/background/21.svg'
import svg22 from 'components/block/ControlButtonsBar/assets/button/double/background/22.svg'
import svg23 from 'components/block/ControlButtonsBar/assets/button/double/background/23.svg'
import svg24 from 'components/block/ControlButtonsBar/assets/button/double/background/24.svg'
import svg25 from 'components/block/ControlButtonsBar/assets/button/double/background/25.svg'
import svg26 from 'components/block/ControlButtonsBar/assets/button/double/background/26.svg'
import svg27 from 'components/block/ControlButtonsBar/assets/button/double/background/27.svg'
import svg28 from 'components/block/ControlButtonsBar/assets/button/double/background/28.svg'
import svg29 from 'components/block/ControlButtonsBar/assets/button/double/background/29.svg'
import svg3 from 'components/block/ControlButtonsBar/assets/button/double/background/3.svg'
import svg30 from 'components/block/ControlButtonsBar/assets/button/double/background/30.svg'
import svg31 from 'components/block/ControlButtonsBar/assets/button/double/background/31.svg'
import svg4 from 'components/block/ControlButtonsBar/assets/button/double/background/4.svg'
import svg5 from 'components/block/ControlButtonsBar/assets/button/double/background/5.svg'
import svg6 from 'components/block/ControlButtonsBar/assets/button/double/background/6.svg'
import svg7 from 'components/block/ControlButtonsBar/assets/button/double/background/7.svg'
import svg8 from 'components/block/ControlButtonsBar/assets/button/double/background/8.svg'
import svg9 from 'components/block/ControlButtonsBar/assets/button/double/background/9.svg'
import svgShadow1 from 'components/block/ControlButtonsBar/assets/button/double/backgroundShadow/1.svg'
import svgShadow10 from 'components/block/ControlButtonsBar/assets/button/double/backgroundShadow/10.svg'
import svgShadow11 from 'components/block/ControlButtonsBar/assets/button/double/backgroundShadow/11.svg'
import svgShadow12 from 'components/block/ControlButtonsBar/assets/button/double/backgroundShadow/12.svg'
import svgShadow13 from 'components/block/ControlButtonsBar/assets/button/double/backgroundShadow/13.svg'
import svgShadow14 from 'components/block/ControlButtonsBar/assets/button/double/backgroundShadow/14.svg'
import svgShadow15 from 'components/block/ControlButtonsBar/assets/button/double/backgroundShadow/15.svg'
import svgShadow16 from 'components/block/ControlButtonsBar/assets/button/double/backgroundShadow/16.svg'
import svgShadow17 from 'components/block/ControlButtonsBar/assets/button/double/backgroundShadow/17.svg'
import svgShadow18 from 'components/block/ControlButtonsBar/assets/button/double/backgroundShadow/18.svg'
import svgShadow19 from 'components/block/ControlButtonsBar/assets/button/double/backgroundShadow/19.svg'
import svgShadow2 from 'components/block/ControlButtonsBar/assets/button/double/backgroundShadow/2.svg'
import svgShadow20 from 'components/block/ControlButtonsBar/assets/button/double/backgroundShadow/20.svg'
import svgShadow21 from 'components/block/ControlButtonsBar/assets/button/double/backgroundShadow/21.svg'
import svgShadow22 from 'components/block/ControlButtonsBar/assets/button/double/backgroundShadow/22.svg'
import svgShadow23 from 'components/block/ControlButtonsBar/assets/button/double/backgroundShadow/23.svg'
import svgShadow24 from 'components/block/ControlButtonsBar/assets/button/double/backgroundShadow/24.svg'
import svgShadow25 from 'components/block/ControlButtonsBar/assets/button/double/backgroundShadow/25.svg'
import svgShadow26 from 'components/block/ControlButtonsBar/assets/button/double/backgroundShadow/26.svg'
import svgShadow27 from 'components/block/ControlButtonsBar/assets/button/double/backgroundShadow/27.svg'
import svgShadow28 from 'components/block/ControlButtonsBar/assets/button/double/backgroundShadow/28.svg'
import svgShadow29 from 'components/block/ControlButtonsBar/assets/button/double/backgroundShadow/29.svg'
import svgShadow3 from 'components/block/ControlButtonsBar/assets/button/double/backgroundShadow/3.svg'
import svgShadow30 from 'components/block/ControlButtonsBar/assets/button/double/backgroundShadow/30.svg'
import svgShadow31 from 'components/block/ControlButtonsBar/assets/button/double/backgroundShadow/31.svg'
import svgShadow4 from 'components/block/ControlButtonsBar/assets/button/double/backgroundShadow/4.svg'
import svgShadow5 from 'components/block/ControlButtonsBar/assets/button/double/backgroundShadow/5.svg'
import svgShadow6 from 'components/block/ControlButtonsBar/assets/button/double/backgroundShadow/6.svg'
import svgShadow7 from 'components/block/ControlButtonsBar/assets/button/double/backgroundShadow/7.svg'
import svgShadow8 from 'components/block/ControlButtonsBar/assets/button/double/backgroundShadow/8.svg'
import svgShadow9 from 'components/block/ControlButtonsBar/assets/button/double/backgroundShadow/9.svg'

export const backgrounds = [
  svg1,
  svg2,
  svg3,
  svg4,
  svg5,
  svg6,
  svg7,
  svg8,
  svg9,
  svg10,
  svg11,
  svg12,
  svg13,
  svg14,
  svg15,
  svg16,
  svg17,
  svg18,
  svg19,
  svg20,
  svg21,
  svg22,
  svg23,
  svg24,
  svg25,
  svg26,
  svg27,
  svg28,
  svg29,
  svg30,
  svg31,
]

export const backgroundsShadow = [
  svgShadow1,
  svgShadow2,
  svgShadow3,
  svgShadow4,
  svgShadow5,
  svgShadow6,
  svgShadow7,
  svgShadow8,
  svgShadow9,
  svgShadow10,
  svgShadow11,
  svgShadow12,
  svgShadow13,
  svgShadow14,
  svgShadow15,
  svgShadow16,
  svgShadow17,
  svgShadow18,
  svgShadow19,
  svgShadow20,
  svgShadow21,
  svgShadow22,
  svgShadow23,
  svgShadow24,
  svgShadow25,
  svgShadow26,
  svgShadow27,
  svgShadow28,
  svgShadow29,
  svgShadow30,
  svgShadow31,
]
