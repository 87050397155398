import React from 'react'

import { FormattedMessage } from 'react-intl'
import { useLocation } from 'react-router'

import { RouterLink } from 'components/designSystem/Link/RouterLink'
import { FilterSvg } from 'components/designSystem/svgr/FilterSvg'
import { SettingsSvg } from 'components/designSystem/svgr/SettingsSvg'
import { photoRatingSettingsPath } from 'components/page/PhotoRating/constants'
import { REVERT_RATING_BUTTON } from 'components/page/RatingWave/Rating.constants'
import {
  searchPath,
  searchSettingsPath,
} from 'components/page/Search/Search.paths'
import { profilePath, ratingPath, settingsModalPath } from 'components/paths'
import { ModalBackButton } from 'components/presentational/button/ModalBackButton'
import { mergeAllUrls } from 'functions/mergeAllUrls'
import { useCurrentUserRouteMatch } from 'hooks/useCurrentUserRouteMatch'
import { useUserId } from 'hooks/useUserId'

import { TemplateMenu } from './common/TemplateMenu'
import { useNavigationSettings } from './hooks/useNavigationSettings'

export const NavigationSettings = () => {
  const { pathname } = useLocation()
  const userId = useUserId()

  const {
    isRatingPath,
    isSearchPath,
    isEventListPath,
    isChatPath,
    isProfilePath,
  } = useNavigationSettings()

  const isCurrentUserPath = useCurrentUserRouteMatch(pathname)

  if (isRatingPath) {
    return (
      <TemplateMenu
        data-name="title-section-rating"
        firstIcon={
          <RouterLink
            to={mergeAllUrls(ratingPath, photoRatingSettingsPath)}
            data-name="rating-settings-action"
            black={true}
          >
            <FilterSvg width={24} height={24} />
          </RouterLink>
        }
        secondIcon={
          <div
            id={REVERT_RATING_BUTTON}
            style={{
              width: '24px',
              height: '24px',
            }}
          ></div>
        }
      >
        <FormattedMessage id="app.rating" defaultMessage="Знакомства" />
      </TemplateMenu>
    )
  }

  if (isSearchPath) {
    return (
      <TemplateMenu
        data-name="title-section-search"
        firstIcon={
          <RouterLink
            to={mergeAllUrls(searchPath, searchSettingsPath)}
            data-name="search-settings-icon"
            black={true}
          >
            <FilterSvg width={24} height={24} />
          </RouterLink>
        }
      >
        <FormattedMessage id="app.search" defaultMessage="Поиск" />
      </TemplateMenu>
    )
  }

  if (isEventListPath) {
    return (
      <TemplateMenu data-name="title-section-event">
        <FormattedMessage
          id="popularity.statistic.hitlist"
          defaultMessage="События"
        />
      </TemplateMenu>
    )
  }

  if (isCurrentUserPath) {
    return (
      <TemplateMenu
        data-name="title-section-current-profile"
        firstIcon={
          <RouterLink
            to={mergeAllUrls(profilePath, userId, settingsModalPath)}
            data-name="link-main-settings-action"
            black={true}
          >
            <SettingsSvg width={24} height={24} />
          </RouterLink>
        }
      >
        <FormattedMessage id="app.profile.next" defaultMessage="Профиль" />
      </TemplateMenu>
    )
  }

  if (isChatPath) {
    return (
      <TemplateMenu data-name="title-section-chat">
        <FormattedMessage id="settings.messages" defaultMessage="Сообщения" />
      </TemplateMenu>
    )
  }

  if (isProfilePath) {
    return (
      <TemplateMenu
        data-name="title-section-foreign-profile"
        backIcon={<ModalBackButton />}
      />
    )
  }

  return <div></div>
}
