import React from 'react'

import { styled } from '@linaria/react'
import { FormattedMessage } from 'react-intl'

import { BlogLogoSvg } from 'components/designSystem/svgr/BlogLogoSvg'
import { blogPath } from 'components/presentational/Navigation/Navigation.constants'

import { Typography } from '../Typography/Typography'

export const BlogLink = () => {
  return (
    <Parent href={blogPath}>
      <Typography fontSize={14}>
        <FormattedMessage
          id="app.button.blog.title"
          defaultMessage="Об отношениях и свиданиях читать"
        />
      </Typography>
      <BlogLogoSvg />
    </Parent>
  )
}

const Parent = styled.a`
  display: grid;
  border-radius: 16px;
  padding: 0 var(--spacing-24px, 24px);
  background: var(--rose-opacity-soft, #fbb9df3d);
  text-decoration: none;
  grid-auto-flow: column;
  align-items: center;
  gap: var(--spacing-16px, 16px);
  grid-gap: var(--spacing-16px, 16px);
`
