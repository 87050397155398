import React, { FC, PropsWithChildren, ReactNode } from 'react'

import { VoidHandler } from 'common/types'
import { AdBlockBanner } from 'components/banner/AdblockBanner/AdBlockBanner'
import { BannerPopUpRulesCookie } from 'components/banner/BannerPopUpRulesCookie'
import { Footer } from 'components/block/Navigation/Footer'
import { Header } from 'components/block/Navigation/Header'
import { WaveLayoutMainContainer } from 'components/designSystem/layout/WaveLayout/WaveLayoutMainContainer'
import { ModalWrapper } from 'components/layout/ModalLayout/ModalWrapper'

export const WaveLayout: FC<
  PropsWithChildren & { modal: ReactNode; onClick: VoidHandler }
> = ({ children, modal, onClick }) => {
  return (
    <>
      <ModalWrapper onClick={onClick} data-name="internal-content">
        <Header />
        <AdBlockBanner />
        <WaveLayoutMainContainer>{children}</WaveLayoutMainContainer>
        <Footer />
      </ModalWrapper>
      <BannerPopUpRulesCookie />
      {modal}
    </>
  )
}
