/**
 * Данное число было получено от ios приложения.
 *
 */
const magicNumber = 80

export const findBackgroundImage = (
  scrollYPosition: number,
  backgrounds: string[],
  backgroundsShadow: string[]
): string => {
  if (scrollYPosition > 0) {
    const index = Math.trunc(
      Math.min(
        scrollYPosition / (magicNumber / backgrounds.length - 1),
        backgrounds.length - 1
      )
    )

    return `url(${backgrounds[index]}), url(${backgroundsShadow[index]})`
  }

  return 'none'
}

export const findLastImage = (
  backgrounds: string[],
  backgroundsShadow: string[]
) => {
  return `url(${backgrounds[backgrounds.length - 1]}), url(${
    backgroundsShadow[backgroundsShadow.length - 1]
  })`
}

export const preCacheBackground = (
  backgrounds: string[],
  backgroundsShadow: string[]
) => {
  backgrounds.filter(Boolean).map((background) => {
    const img = new Image(0, 0)
    img.src = background
  })

  backgroundsShadow.filter(Boolean).map((background) => {
    const img = new Image(0, 0)
    img.src = background
  })
}
