import { useMemo } from 'react'

import { userProfilePath } from 'components/paths'
import { findMatchByPath } from 'functions/findMatch'
import { useShallowEqualSelector } from 'hooks/useShallowEqualSelector'

export const useCurrentUserRouteMatch = (pathname: string) => {
  const { profile } = useShallowEqualSelector(
    ({ authorizationReducer: { profile } }) => ({
      profile,
    })
  )

  const match = useMemo(
    () => findMatchByPath(userProfilePath, pathname, false),
    [pathname]
  )

  const parsedUserId = useMemo(() => {
    if (match && match.params && match.params.userId) {
      return Number(match.params.userId)
    }

    return undefined
  }, [match])

  return useMemo(() => profile && profile.id && profile.id === parsedUserId, [
    parsedUserId,
    profile,
  ])
}
