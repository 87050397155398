import React, { SVGProps } from 'react'

export const EventSvg = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={64}
    height={64}
    viewBox="0 0 64 64"
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      d="M48.454 36.54c.87-1.823 3.462-1.823 4.332 0l.694 1.455a5.926 5.926 0 0 0 4.574 3.323l1.599.21c2.001.264 2.803 2.73 1.338 4.12l-1.17 1.11a5.926 5.926 0 0 0-1.746 5.377l.293 1.586c.368 1.985-1.73 3.51-3.504 2.547l-1.417-.77a5.926 5.926 0 0 0-5.654 0l-1.417.77c-1.774.962-3.873-.562-3.505-2.547l.294-1.585a5.926 5.926 0 0 0-1.748-5.377l-1.169-1.11c-1.464-1.39-.663-3.857 1.339-4.12l1.598-.211a5.926 5.926 0 0 0 4.574-3.323l.695-1.456Z"
    />
    <path
      fill="currentColor"
      d="M1.801 28.32c.98 10.549 10.544 18.257 16.997 22.307l.831.524h.001C23.17 53.387 26.856 55.716 31 56c2.129-.146 4.136-.83 6.065-1.766.867-.42 1.436-1.326 1.208-2.262a6.253 6.253 0 0 0-2.399-3.579c-4.408-3.206-2.892-10.123 2.452-11.191l2.95-.59a6.377 6.377 0 0 0 4.452-3.401C47.853 28.96 52.186 28.43 55 32c2.036 2.583 4.897-.429 5.2-3.68C61.687 12.251 50.31 9.092 50.31 9.092c-13.745-4.417-18.179 5.74-19.31 9.434-1.133-3.694-5.567-13.852-19.312-9.434 0 0-11.377 3.159-9.888 19.226Z"
    />
  </svg>
)
