import React, { FC, PropsWithChildren, useMemo } from 'react'

import { css, cx } from '@linaria/core'
import { styled } from '@linaria/react'
import { useLocation } from 'react-router'

import { usePreCacheControlButtons } from 'components/block/ControlButtonsBar/hooks/usePreCacheControlButtons'
import { navigationHeight } from 'components/block/Navigation/constants'
import { SidebarSwitch } from 'components/block/Sidebar/SidebarSwitch'
import { breakpoints } from 'components/designSystem/shared/breakpoints'
import { ContainerSize } from 'components/designSystem/shared/containerSize'
import { SidebarSize } from 'components/designSystem/shared/sidebar'
import { profilePath, ratingPath } from 'components/paths'
import { removeLocaleFromPath2 } from 'functions/language'
import { useAuthorized } from 'hooks/useAuthorized'
import { useCurrentUserRouteMatch } from 'hooks/useCurrentUserRouteMatch'

export const WaveLayoutMainContainer: FC<PropsWithChildren> = ({
  children,
}) => {
  usePreCacheControlButtons()

  const { pathname } = useLocation()
  const _pathname = removeLocaleFromPath2(pathname)
  const isCurrentUserPath = useCurrentUserRouteMatch(_pathname)
  const authorized = useAuthorized()

  const fillHeight = useMemo(() => {
    const pathnameAccept =
      _pathname.startsWith(ratingPath) ||
      (_pathname.startsWith(profilePath) && !isCurrentUserPath)

    if (pathnameAccept) {
      if (authorized) {
        return ratingStyleAuthorized
      }

      return ratingStyleNotAuthorized
    }
  }, [_pathname, isCurrentUserPath, authorized])

  return (
    <Parent className={cx(fillHeight)}>
      <Sidebar>
        <SidebarSwitch />
      </Sidebar>
      <Main className={cx(layoutPadding)}>{children}</Main>
    </Parent>
  )
}

const Parent = styled.div`
  display: grid;
  flex-grow: 1;
  gap: var(--spacing-32px, 32px);
  grid-gap: var(--spacing-32px, 32px);
  justify-content: stretch;
  width: 100%;

  @media screen and (min-width: ${breakpoints.tablet}px) {
    margin: var(--spacing-16px, 16px) 0;
    width: ${ContainerSize.tablet}px;
  }

  @media screen and (min-width: ${breakpoints.middleDesktop}px) {
    width: ${ContainerSize.middle}px;
    grid-template-columns: ${SidebarSize.middle}px 1fr;
  }

  @media screen and (min-width: ${breakpoints.largeDesktop}px) {
    width: ${ContainerSize.large}px;
    grid-template-columns: ${SidebarSize.large}px 1fr;
  }
`
/**
 * Высота навигации(сверху, снизу) + у каждой навигации border в 1px
 * Почему то в linaria в функции css не работает template variables
 */
const height = `
  height: calc(100vh - ${navigationHeight * 2 + 2}px);
  height: calc(100dvh - ${navigationHeight * 2 + 2}px);
`
// 56 высота кнопок для неавторизованного + отступ сверху и снизу
const heightNotAuthorized = `
  height: calc(100vh - ${navigationHeight * 2 + 2}px);
  height: calc(100dvh - ${navigationHeight + 2 + 56 + 24}px);
`

const ratingStyleAuthorized = css`
  @media screen and (max-width: ${breakpoints.tablet}px) {
    ${height}
  }
`
const ratingStyleNotAuthorized = css`
  @media screen and (max-width: ${breakpoints.tablet}px) {
    ${heightNotAuthorized}
  }
`

const Sidebar = styled.aside`
  display: flex;

  @media screen and (max-width: ${breakpoints.middleDesktop}px) {
    display: none;
  }
`

const Main = styled.section`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  /** Почему то без этого flex разъезжается */
  min-width: 0;

  min-height: 100%;
  max-height: 100%;
  height: 100%;
`

const layoutPadding = css`
  @media screen and (max-width: ${breakpoints.tablet}px) {
    padding: 0 var(--spacing-12px, 12px);
  }
`
