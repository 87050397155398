import React, { FC } from 'react'

import { LogoMambaSvg } from 'components/designSystem/svgr/LogoMambaSvg'

type Size = 'xxs' | 'xs' | 's' | 'm' | 'l' | 'xl'

export const LogoMambaSwitchSize: FC<{ size?: Size }> = ({ size = 'xs' }) => {
  return (
    <LogoMambaSvg width={logoSize[size].width} height={logoSize[size].height} />
  )
}

const logoSize: Record<Size, Record<string, number>> = {
  xxs: {
    width: 56,
    height: 12,
  },
  xs: {
    width: 93,
    height: 20,
  },
  s: {
    width: 149,
    height: 32,
  },
  m: {
    width: 280,
    height: 60,
  },
  l: {
    width: 467,
    height: 100,
  },
  xl: {
    width: 560,
    height: 120,
  },
} as const
