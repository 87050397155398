import { useEffect } from 'react'

import {
  backgrounds as multiBackground,
  backgroundsShadow as multiBackgroundShadow,
} from '../assets/button/double/backgroundImages'
import {
  background as singleBackground,
  backgroundShadow as singleBackgroundShadow,
} from '../assets/button/single/mapBackground'
import { preCacheBackground } from '../ControlButtonsBar.functions'

/**
 * Background кнопок для рейтинга и профиля.
 * @returns
 */
export const usePreCacheControlButtons = () => {
  useEffect(() => {
    preCacheBackground(multiBackground, multiBackgroundShadow)
    preCacheBackground(singleBackground, singleBackgroundShadow)
  }, [])

  return null
}
