import svg1 from 'components/block/ControlButtonsBar/assets/button/single/background/1.svg'
import svg10 from 'components/block/ControlButtonsBar/assets/button/single/background/10.svg'
import svg11 from 'components/block/ControlButtonsBar/assets/button/single/background/11.svg'
import svg12 from 'components/block/ControlButtonsBar/assets/button/single/background/12.svg'
import svg13 from 'components/block/ControlButtonsBar/assets/button/single/background/13.svg'
import svg14 from 'components/block/ControlButtonsBar/assets/button/single/background/14.svg'
import svg15 from 'components/block/ControlButtonsBar/assets/button/single/background/15.svg'
import svg16 from 'components/block/ControlButtonsBar/assets/button/single/background/16.svg'
import svg17 from 'components/block/ControlButtonsBar/assets/button/single/background/17.svg'
import svg18 from 'components/block/ControlButtonsBar/assets/button/single/background/18.svg'
import svg19 from 'components/block/ControlButtonsBar/assets/button/single/background/19.svg'
import svg2 from 'components/block/ControlButtonsBar/assets/button/single/background/2.svg'
import svg20 from 'components/block/ControlButtonsBar/assets/button/single/background/20.svg'
import svg21 from 'components/block/ControlButtonsBar/assets/button/single/background/21.svg'
import svg22 from 'components/block/ControlButtonsBar/assets/button/single/background/22.svg'
import svg3 from 'components/block/ControlButtonsBar/assets/button/single/background/3.svg'
import svg4 from 'components/block/ControlButtonsBar/assets/button/single/background/4.svg'
import svg5 from 'components/block/ControlButtonsBar/assets/button/single/background/5.svg'
import svg6 from 'components/block/ControlButtonsBar/assets/button/single/background/6.svg'
import svg7 from 'components/block/ControlButtonsBar/assets/button/single/background/7.svg'
import svg8 from 'components/block/ControlButtonsBar/assets/button/single/background/8.svg'
import svg9 from 'components/block/ControlButtonsBar/assets/button/single/background/9.svg'
import svgShadow1 from 'components/block/ControlButtonsBar/assets/button/single/backgroundShadow/1.svg'
import svgShadow10 from 'components/block/ControlButtonsBar/assets/button/single/backgroundShadow/10.svg'
import svgShadow11 from 'components/block/ControlButtonsBar/assets/button/single/backgroundShadow/11.svg'
import svgShadow12 from 'components/block/ControlButtonsBar/assets/button/single/backgroundShadow/12.svg'
import svgShadow13 from 'components/block/ControlButtonsBar/assets/button/single/backgroundShadow/13.svg'
import svgShadow14 from 'components/block/ControlButtonsBar/assets/button/single/backgroundShadow/14.svg'
import svgShadow15 from 'components/block/ControlButtonsBar/assets/button/single/backgroundShadow/15.svg'
import svgShadow16 from 'components/block/ControlButtonsBar/assets/button/single/backgroundShadow/16.svg'
import svgShadow17 from 'components/block/ControlButtonsBar/assets/button/single/backgroundShadow/17.svg'
import svgShadow18 from 'components/block/ControlButtonsBar/assets/button/single/backgroundShadow/18.svg'
import svgShadow19 from 'components/block/ControlButtonsBar/assets/button/single/backgroundShadow/19.svg'
import svgShadow2 from 'components/block/ControlButtonsBar/assets/button/single/backgroundShadow/2.svg'
import svgShadow20 from 'components/block/ControlButtonsBar/assets/button/single/backgroundShadow/20.svg'
import svgShadow21 from 'components/block/ControlButtonsBar/assets/button/single/backgroundShadow/21.svg'
import svgShadow22 from 'components/block/ControlButtonsBar/assets/button/single/backgroundShadow/22.svg'
import svgShadow3 from 'components/block/ControlButtonsBar/assets/button/single/backgroundShadow/3.svg'
import svgShadow4 from 'components/block/ControlButtonsBar/assets/button/single/backgroundShadow/4.svg'
import svgShadow5 from 'components/block/ControlButtonsBar/assets/button/single/backgroundShadow/5.svg'
import svgShadow6 from 'components/block/ControlButtonsBar/assets/button/single/backgroundShadow/6.svg'
import svgShadow7 from 'components/block/ControlButtonsBar/assets/button/single/backgroundShadow/7.svg'
import svgShadow8 from 'components/block/ControlButtonsBar/assets/button/single/backgroundShadow/8.svg'
import svgShadow9 from 'components/block/ControlButtonsBar/assets/button/single/backgroundShadow/9.svg'

export const background = [
  svg1,
  svg2,
  svg3,
  svg4,
  svg5,
  svg6,
  svg7,
  svg8,
  svg9,
  svg10,
  svg11,
  svg12,
  svg13,
  svg14,
  svg15,
  svg16,
  svg17,
  svg18,
  svg19,
  svg20,
  svg21,
  svg22,
]

export const backgroundShadow = [
  svgShadow1,
  svgShadow2,
  svgShadow3,
  svgShadow4,
  svgShadow5,
  svgShadow6,
  svgShadow7,
  svgShadow8,
  svgShadow9,
  svgShadow10,
  svgShadow11,
  svgShadow12,
  svgShadow13,
  svgShadow14,
  svgShadow15,
  svgShadow16,
  svgShadow17,
  svgShadow18,
  svgShadow19,
  svgShadow20,
  svgShadow21,
  svgShadow22,
]
