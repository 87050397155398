import { useMemo } from 'react'

import { useLocation } from 'react-router'

import { eventListPath } from 'components/page/EventList/EventList.paths'
import { searchPath } from 'components/page/Search/Search.paths'
import {
  chatPath,
  contactListPath,
  profilePath,
  ratingPath,
} from 'components/paths'
import { removeLocaleFromPath2 } from 'functions/language'

export const useNavigationSettings = () => {
  const { pathname } = useLocation()

  return useMemo(() => {
    const _pathname = removeLocaleFromPath2(pathname)
    const isRatingPath = _pathname.startsWith(ratingPath)
    const isSearchPath = _pathname.startsWith(searchPath)
    const isEventListPath = _pathname.startsWith(eventListPath)
    const isChatPath =
      _pathname.startsWith(chatPath) || _pathname.startsWith(contactListPath)
    const isProfilePath = _pathname.startsWith(profilePath)

    return {
      isRatingPath,
      isSearchPath,
      isEventListPath,
      isChatPath,
      isProfilePath,
    }
  }, [pathname])
}
