import React, { FC } from 'react'

import { css, cx } from '@linaria/core'
import { styled } from '@linaria/react'
import { useLocation } from 'react-router'

import { WithClassName, WithDataName } from 'common/types'
import { navigationHeight } from 'components/block/Navigation/constants'
import { CreateProfileOrLoginBlockInner } from 'components/block/Navigation/CreateProfileOrLoginBlockInner'
import { Avatar } from 'components/designSystem/Avatar/Avatar'
import { RouterLink } from 'components/designSystem/Link/RouterLink'
import { breakpoints } from 'components/designSystem/shared/breakpoints'
import { CardsSvg } from 'components/designSystem/svgr/CardsSvg'
import { ChatSvg } from 'components/designSystem/svgr/ChatSvg'
import { EventSvg } from 'components/designSystem/svgr/EventSvg'
import { MagnifierSvg } from 'components/designSystem/svgr/MagnifierSvg'
import {
  EventListFilter,
  eventListPath,
} from 'components/page/EventList/EventList.paths'
import { searchPath } from 'components/page/Search/Search.paths'
import {
  contactListPath,
  contactPath,
  profilePath,
  ratingPath,
} from 'components/paths'
import { useHasUserRestrictions } from 'functions/hasUserRestrictions'
import { mergeAllUrls } from 'functions/mergeAllUrls'
import { useAuthorized } from 'hooks/useAuthorized'
import { useCurrentUserRouteMatch } from 'hooks/useCurrentUserRouteMatch'
import { useShallowEqualSelector } from 'hooks/useShallowEqualSelector'

import { isActiveTab } from './function/isActiveTab'

/**
 * @param isMobileBar - блок с кнопками будет отображаться снизу экрана как на мобильных
 */
export const TabBar: FC<
  { isMobileBar?: boolean } & WithClassName & WithDataName
> = ({ className, isMobileBar = false, ...rest }) => {
  const { pathname } = useLocation()
  const authorized = useAuthorized()
  const userHasRestrictions = useHasUserRestrictions()
  const { id, smallPhoto } = useShallowEqualSelector(
    ({
      authorizationReducer: {
        profile: { id },
      },
      userReducer: { smallPhoto },
    }) => ({
      id,
      smallPhoto,
    })
  )

  const isCurrentUserPath = useCurrentUserRouteMatch(pathname)

  if (userHasRestrictions) {
    return <div />
  }

  if (!authorized) {
    if (isMobileBar) {
      return (
        <Parent className={cx(className)} data-name={rest['data-name']}>
          <CreateProfileOrLoginBlockInner variant="inline" />
        </Parent>
      )
    }

    return (
      <Parent className={cx(className)} data-name={rest['data-name']}>
        <Link
          to={searchPath}
          className={cx(
            defaultCss,
            isActiveTab(pathname, searchPath) && activeCss
          )}
          data-name="link-main-menu__search-action"
        >
          <MagnifierSvg className={cx(iconSizeCss)} />
        </Link>
        <Link
          to={ratingPath}
          className={cx(
            defaultCss,
            isActiveTab(pathname, ratingPath) && activeCss
          )}
          data-name="link-main-menu__rating-action"
        >
          <CardsSvg className={cx(iconSizeCss)} />
        </Link>
      </Parent>
    )
  }

  return (
    <Parent className={cx(className)} data-name={rest['data-name']}>
      <Link
        to={mergeAllUrls(profilePath, id!)}
        className={cx(isMobileBar && changeOrderCss)}
        data-name="profile-action"
      >
        <StyledAvatar
          imageSource={smallPhoto}
          className={cx(isCurrentUserPath && activeAvatarCss)}
        />
      </Link>

      <Link
        to={searchPath}
        className={cx(
          defaultCss,
          isActiveTab(pathname, searchPath) && activeCss
        )}
        data-name="link-main-menu__search-action"
      >
        <MagnifierSvg className={cx(iconSizeCss)} />
      </Link>

      <Link
        to={mergeAllUrls(eventListPath, EventListFilter.All)}
        className={cx(
          defaultCss,
          isActiveTab(pathname, eventListPath) && activeCss
        )}
        data-name="events-action"
      >
        <EventSvg className={cx(iconSizeCss)} />
      </Link>

      <Link
        to={ratingPath}
        className={cx(
          defaultCss,
          isActiveTab(pathname, ratingPath) && activeCss
        )}
        data-name="link-main-menu__rating-action"
      >
        <CardsSvg className={cx(iconSizeCss)} />
      </Link>

      <ChatLink
        to={contactListPath}
        className={cx(
          defaultCss,
          isActiveTab(pathname, contactPath) && activeCss
        )}
        data-main="messenger-action"
      >
        <ChatSvg className={cx(iconSizeCss)} />
      </ChatLink>
    </Parent>
  )
}

const defaultCss = css`
  color: var(--foreground-disabled, #627f9966);
`

const activeCss = css`
  color: var(--foreground-default, #2e2a29);
`

const iconSizeCss = css`
  width: 32px;
  height: 32px;

  @media screen and (max-width: ${breakpoints.tablet}px) {
    height: 24px;
    width: 24px;
  }
`

const Parent = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: ${navigationHeight}px;
`

const Link = styled(RouterLink)`
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  min-height: 100%;
  transition: color 0.3s ease-in-out;

  &:hover {
    color: var(--foreground-default, #2e2a29);
  }
`

const ChatLink = styled(Link)`
  display: none;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  min-height: 100%;

  @media screen and (max-width: ${breakpoints.middleDesktop}px) {
    display: flex;
  }
`

const changeOrderCss = css`
  order: 1;
`

const StyledAvatar = styled(Avatar)`
  width: 40px;
  height: 40px;
  border-radius: 14px;

  svg {
    width: 100%;
    height: 100%;
  }

  @media screen and (max-width: ${breakpoints.tablet}px) {
    height: 24px;
    width: 24px;
    border-radius: 6px;
  }
`

const activeAvatarCss = css`
  box-shadow: 0 0 0 2px var(--accent-primary-default, #2e2a29);
`
