import React, { FC, MouseEventHandler } from 'react'

import { css, cx } from '@linaria/core'
import { Link } from 'react-router-dom'

import { WithChildren, WithClassName, WithDataName } from 'common/types'

import { ButtonInner } from './ButtonInner'
import { Size } from './types.common'
import { Anchor } from '../Link/Anchor'
import { formaDJRCyrillicMicroFontFamily } from '../shared/fonts/formaDJRCyrillic/formaDJRCyrillicFontFamily'

export const SecondaryButton: FC<
  {
    to?: string
    href?: string
    loading?: boolean
    size?: Size
    disabled?: boolean
    fullWidth?: boolean
    onClick?: MouseEventHandler<HTMLElement>
  } & WithChildren &
    WithDataName &
    WithClassName
> = ({
  className,
  to,
  href,
  children,
  loading,
  size = 'L',
  disabled,
  fullWidth,
  ...restProps
}) => {
  const classNames = cx(
    secondaryButtonCss,
    buttonSizesMapCss[size],
    fullWidth && fullWidthCss,
    loading ? loadingCss : statesCss
  )

  const _disabled = loading || disabled

  const innerElement = (
    <ButtonInner size={size} loading={loading}>
      {children}
    </ButtonInner>
  )

  if (to) {
    return (
      <div className={className}>
        <Link to={to} className={classNames} {...restProps}>
          {innerElement}
        </Link>
      </div>
    )
  }

  if (href) {
    return (
      <div className={className}>
        <Anchor href={href} className={classNames} {...restProps}>
          {innerElement}
        </Anchor>
      </div>
    )
  }

  return (
    <div className={className}>
      <button
        type="button"
        className={classNames}
        disabled={_disabled}
        {...restProps}
      >
        {innerElement}
      </button>
    </div>
  )
}

const secondaryButtonCss = css`
  width: 100%;
  display: inline-flex;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  transition: 0.2s;
  justify-content: center;
  align-items: center;
  -webkit-appearance: none;
  text-align: center;
  outline: none;
  border: none;
  border-radius: 100px;
  font-style: normal;
  font-weight: 500;
  font-family: ${formaDJRCyrillicMicroFontFamily};
  color: var(--rose-opacity-on-rose, #2e2a29);
  background-color: var(--rose-opacity-default, #fbb9df7a);

  circle {
    fill: black;
  }
`

const buttonSizesMapCss: Record<Size, ReturnType<typeof css>> = {
  L: css`
    padding: var(--spacing-14px, 14px) var(--spacing-24px, 24px);
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.32px;
  `,
  M: css`
    padding: var(--spacing-12px, 12px) var(--spacing-18px, 18px);
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.32px;
  `,
  S: css`
    padding: var(--spacing-8px, 8px) var(--spacing-14px, 14px);
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.28px;
  `,
}

const fullWidthCss = css`
  width: 100%;
`

const statesCss = css`
  @media (hover: hover) and (pointer: fine) {
    &:hover {
      background-color: var(--rose-opacity-hover, #fbb9dfa3);
    }
  }

  &:active {
    background-color: var(--rose-opacity-press, #fbb9dfcc);
  }

  &:disabled {
    color: var(--foreground-disabled, rgba(98, 127, 153, 0.4));
    background: var(--background-opacity-disabled, rgba(98, 127, 153, 0.24));
    cursor: not-allowed;
  }
`

const loadingCss = css`
  cursor: progress;
`
