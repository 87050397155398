import React, { FC } from 'react'

import { styled } from '@linaria/react'

import { navigationHeight } from 'components/block/Navigation/constants'
import { breakpoints } from 'components/designSystem/shared/breakpoints'
import { ContainerSize } from 'components/designSystem/shared/containerSize'
import { SidebarSize } from 'components/designSystem/shared/sidebar'

import { NavigationBar } from './NavigationBar'
import { TabBar } from './TabBar'
import { StickyObserver } from '../StickyObserver/StickyObserver'

export const Header: FC = () => {
  return (
    <Parent data-name="main-navigation" position="top">
      <Body>
        <TabBarDesktop data-name="tab-bar-desktop" />
        <NavigationBar />
      </Body>
    </Parent>
  )
}

const Parent = styled(StickyObserver)`
  display: grid;
  grid-auto-flow: column;
  position: sticky;
  top: 0;
  justify-content: center;
  align-items: center;
  z-index: 2;
  transition: border-color 0.3s ease-in-out;

  min-width: 100%;

  @media screen and (max-width: ${breakpoints.tablet}px) {
    justify-content: stretch;
    margin-bottom: 0;
  }
`

const Body = styled.div`
  display: grid;
  gap: var(--spacing-32px, 32px);
  grid-gap: var(--spacing-32px, 32px);
  margin: var(--spacing-64px, 64px) 0 var(--spacing-16px, 16px);
  height: ${navigationHeight}px;
  z-index: 2;

  @media screen and (min-width: ${breakpoints.tablet}px) {
    width: ${ContainerSize.tablet}px;
  }

  @media screen and (min-width: ${breakpoints.middleDesktop}px) {
    width: ${ContainerSize.middle}px;
    grid-template-columns: ${SidebarSize.middle}px 1fr;
  }

  @media screen and (min-width: ${breakpoints.largeDesktop}px) {
    width: ${ContainerSize.large}px;
    grid-template-columns: ${SidebarSize.large}px 1fr;
  }

  @media screen and (max-width: ${breakpoints.tablet}px) {
    margin: 0;
  }
`

const TabBarDesktop = styled(TabBar)`
  display: flex;
  justify-content: space-around;
  padding: 0 var(--spacing-24px, 24px);

  @media screen and (max-width: ${breakpoints.middleDesktop}px) {
    display: none;
  }
`
