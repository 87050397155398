import React, { FC } from 'react'

import { VoidHandler, WithChildren, WithDataName } from 'common/types'
import { modalOpenAttribute } from 'components/layout/ModalLayout/modalOpen'
import { WrapperElement } from 'components/layout/ModalLayout/WrapperElement'
import { useModalOpened } from 'hooks/useModalOpened'
import { useOperationSystem } from 'hooks/useOperationSystem'
import { useRedesignLayout } from 'hooks/useRedesign'

export const ModalWrapper: FC<
  {
    isAuthPage?: boolean
    isMambaPartner?: boolean
    onClick?: VoidHandler
  } & WithDataName &
    WithChildren
> = (props) => {
  const modalOpened = useModalOpened()
  const operationSystem = useOperationSystem()
  const redesignLayout = useRedesignLayout()

  const newProps = {
    ...props,
    [modalOpenAttribute]: modalOpened,
  }

  const style = redesignLayout
    ? {
        alignItems: 'center',
      }
    : {}

  /* id необходимо для знакомства для фикса https://redmine.mamba.ru/issues/99606 */
  return (
    <WrapperElement
      id="app-wrapper"
      {...newProps}
      $operationSystem={operationSystem}
      style={style}
    />
  )
}
