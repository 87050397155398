import React, {
  FC,
  PropsWithChildren,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'

import { css, cx } from '@linaria/core'
import { useLocation } from 'react-router-dom'

import { WithClassName } from 'common/types'

type position = 'top' | 'bottom'

/**
 * TODO: пока оставил так, возможно на будущее надо будет переделать для того чтобы снаружи передавать стили
 * @param param0
 * @returns
 */
export const StickyObserver: FC<
  PropsWithChildren & WithClassName & { position?: position; offset?: number }
> = ({ children, className, position = 'top', offset = 1 }) => {
  const stickyRef = useRef<HTMLDivElement | null>(null)
  const [isActive, setIsActive] = useState(false)
  const pathname = useLocation()

  const handleScroll = useCallback(() => {
    const element = stickyRef.current
    if (!element) return

    if (position === 'top') {
      if (window.scrollY > 0) {
        setIsActive(true)
      } else {
        setIsActive(false)
      }
    } else if (position === 'bottom') {
      // Логика для sticky снизу

      if (
        document.documentElement.scrollHeight - window.scrollY <=
        document.documentElement.offsetHeight
      ) {
        setIsActive(false)
      } else {
        setIsActive(true)
      }
    }
  }, [position])

  useEffect(() => {
    // Проверяем положение элемента сразу при монтировании
    handleScroll()
  }, [handleScroll, pathname])

  useEffect(() => {
    const element = document.getElementById('app-wrapper')

    window.addEventListener('scroll', handleScroll)
    element?.addEventListener('resize', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
      element?.removeEventListener('resize', handleScroll)
    }
  }, [position, offset, handleScroll])

  return (
    <div
      ref={stickyRef}
      className={cx(
        className,
        borderDefault[position],
        isActive && activeCss,
        isActive && borderActive[position]
      )}
    >
      {children}
    </div>
  )
}

const activeCss = css`
  background: var(
    --gradient-top-vertical,
    linear-gradient(180deg, #fff 0%, rgba(255, 255, 255, 0.96) 100%)
  );
`

const borderDefault: Record<position, ReturnType<typeof css>> = {
  top: css`
    border-bottom-color: transparent;
    border-bottom-style: solid;
    border-bottom-width: 1px;
  `,
  bottom: css`
    border-top-color: transparent;
    border-top-style: solid;
    border-top-width: 1px;
  `,
}

const borderActive: Record<position, ReturnType<typeof css>> = {
  top: css`
    border-bottom-color: var(--border-muted, #7f747229);
  `,
  bottom: css`
    border-top-color: var(--border-muted, #7f747229);
  `,
}
