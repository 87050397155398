import React, { SVGProps } from 'react'

export const AvatarEmptySvg = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={128}
    height={128}
    viewBox="0 0 128 128"
    fill="none"
    {...props}
  >
    <path
      fill="#99908E"
      fillRule="evenodd"
      d="M86.575 149.227c5.455-17.521-7.204-34.208-22.614-54.521-1.516-1.999-3.059-4.032-4.613-6.105C41.718 65.096 42.042 59.27 42.204 56.37c.018-.324.034-.612.023-.884-.113-2.703 3.604-15.657 15.994-9.236 9.912 5.136 15.62 1.164 17.234-1.464-.976-6.008-5.97-17.842-18.135-17.121-15.206.9-25.794 11.489-26.92 26.132-1.095 14.23 12.522 31.215 28.87 51.606l1.43 1.785c16.766 20.927 16.215 35.813 10.4 42.04h15.475Z"
      clipRule="evenodd"
    />
    <path
      fill="#99908E"
      d="M81.761 105.549c.21 1.182.965 1.281 1.316 1.182.526-.098 1.526-.532 1.316-1.477-.21-.946-.965-1.183-1.316-1.183-.526 0-1.526.296-1.316 1.478Z"
    />
    <path
      fill="#7F7472"
      d="M26.254 25.25c.194 1.084.886 1.174 1.207 1.084.483-.09 1.4-.488 1.208-1.356-.194-.868-.886-1.085-1.207-1.085-.483 0-1.4.271-1.208 1.356ZM31.798 79.154c.14.789.643.854.877.789.35-.066 1.017-.355.877-.986-.14-.63-.643-.788-.877-.788-.351 0-1.018.197-.877.986Z"
    />
    <path
      fill="#615857"
      d="M72.279 38.292c-.337 5.354 4.116 8.564 7.887 10.104 1.858.759 3.96.661 5.777-.191 11.094-5.21 7.853-15.966 3.217-16.497a9.523 9.523 0 0 0-2.062-.026c-1.95.201-6.039.103-7.965-.256-3.038-.566-6.501 1.258-6.854 6.866Z"
    />
    <path
      fill="#C2BCBB"
      d="M81.988 45.122c-2.523-3.063-7.96-6.983-10.363-8.56-2.072.36-5.744 8.035-7.321 11.827 4.28 0 7.772 4.393 9.912 6.308 1.712 1.532 2.215 3.792 2.253 4.73 1.69 8.223 3.379 9.688 4.393 11.152.81 1.171 1.539.188 1.802-.45.225-.902.968-4.123 2.14-9.8 1.464-7.097.338-11.377-2.816-15.207Z"
    />
    <path
      fill="#615857"
      d="M49.858 38.292c-.336 5.354 4.116 8.564 7.888 10.104 1.857.759 3.96.661 5.776-.191 11.094-5.21 7.853-15.966 3.218-16.497a9.523 9.523 0 0 0-2.063-.026c-1.95.201-6.038.103-7.965-.256-3.037-.566-6.5 1.258-6.854 6.866Z"
    />
    <path
      fill="#615857"
      fillRule="evenodd"
      d="M37.852 36.367c-.929.707-1.53 1.77-1.79 2.527a.617.617 0 1 1-1.167-.4c.316-.924 1.039-2.217 2.21-3.108 1.206-.918 2.864-1.386 4.917-.636 1.174.43 2.128.858 2.965 1.235.843.38 1.549.698 2.266.931 1.39.452 2.86.596 5.363.054a.617.617 0 0 1 .261 1.205c-2.678.58-4.374.444-6.005-.086-.782-.254-1.54-.595-2.356-.963l-.036-.016a40.66 40.66 0 0 0-2.881-1.201c-1.631-.596-2.851-.223-3.747.458Z"
      clipRule="evenodd"
    />
    <path
      fill="#615857"
      d="M73.37 35.763c-1.577-.592-2.628-.247-2.957 0l.246 1.97c.887-1.083 1.93-.615 2.341-.245l.37-1.725Z"
    />
    <path
      fill="#615857"
      fillRule="evenodd"
      d="M84.066 48.322c-1.157-.28-3.403-.074-3.305 2.962.123 3.82.247 13.059-4.188 7.762-.055-.065-.11-.13-.166-.193.036.222.055.417.062.575 1.49 7.251 2.98 9.246 4.006 10.62.138.185.267.358.387.53.81 1.172 1.539.188 1.802-.45.225-.901.968-4.122 2.14-9.8 1.074-5.206.754-8.897-.738-12.006Z"
      clipRule="evenodd"
    />
    <circle cx={96.164} cy={58.06} r={2.714} fill="#ADA6A5" />
  </svg>
)
