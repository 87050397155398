import React, { FC, PropsWithChildren, ReactNode } from 'react'

import { styled } from '@linaria/react'

import { WithDataName } from 'common/types'
import { breakpoints } from 'components/designSystem/shared/breakpoints'
import { Typography } from 'components/designSystem/Typography/Typography'

export const TemplateMenu: FC<
  PropsWithChildren &
    WithDataName & {
      backIcon?: ReactNode
      firstIcon?: ReactNode
      secondIcon?: ReactNode
    }
> = ({ children, backIcon, firstIcon, secondIcon, ...rest }) => {
  const props = {
    fontSize: 20,
    fontWeight: 500,
  } as const

  return (
    <Parent>
      {backIcon && <ParentBack>{backIcon}</ParentBack>}
      {children && (
        <Typography {...props} data-name={rest['data-name']}>
          {children}
        </Typography>
      )}
      <ParentSettingIcons>
        {firstIcon}
        {secondIcon}
      </ParentSettingIcons>
    </Parent>
  )
}

const Parent = styled.div`
  display: grid;
  grid-auto-flow: column;
  justify-content: center;
  align-items: center;
  gap: var(--spacing-32px, 32px);
  grid-gap: var(--spacing-32px, 32px);
  min-height: 100%;
  position: relative;
`

const ParentSettingIcons = styled.div`
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  gap: var(--spacing-16px, 16px);
  grid-gap: var(--spacing-16px, 16px);
  height: 100%;
  justify-content: flex-end;
  color: var(--accent-primary-default, #2e2a29);

  /** Разные дизайн на кнопках надо быть осторожней */
  @media screen and (max-width: ${breakpoints.tablet}px) {
    & > *:nth-child(1) {
      order: 2;
    }

    & > *:nth-child(2) {
      order: 1;
    }

    position: absolute;
    top: 0;
    html[dir='ltr'] & {
      right: 0;
    }
    html[dir='rtl'] & {
      left: 0;
    }
  }
`

const ParentBack = styled.div`
  display: grid;
  align-items: center;
  position: absolute;
  top: 0;
  height: 100%;

  html[dir='ltr'] & {
    left: 0;
  }
  html[dir='rtl'] & {
    right: 0;
  }
`
