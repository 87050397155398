import React, { SVGProps } from 'react'
export const FilterSvg = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={64}
    height={64}
    fill="none"
    viewBox="0 0 64 64"
    {...props}
  >
    <path
      fill="currentColor"
      d="M49.333 13.333a4 4 0 1 0-8 0v.776a10.67 10.67 0 0 0-6.666 9.89 10.67 10.67 0 0 0 6.666 9.892v16.776a4 4 0 0 0 8 0V33.89A10.67 10.67 0 0 0 56 24a10.67 10.67 0 0 0-6.667-9.891v-.776ZM22.667 13.333a4 4 0 1 0-8 0V30.11A10.67 10.67 0 0 0 8 39.999a10.67 10.67 0 0 0 6.667 9.892v.776a4 4 0 1 0 8 0v-.776A10.67 10.67 0 0 0 29.333 40a10.67 10.67 0 0 0-6.666-9.891V13.333Z"
    />
  </svg>
)
