import React, { FC } from 'react'

import { styled } from '@linaria/react'

import wamba from 'components/presentational/Logo/wamba.svg'
import yonja from 'components/presentational/Logo/yonja.svg'
import { useShallowEqualSelector } from 'hooks/useShallowEqualSelector'

import { LogoMambaSwitchSize } from './LogoMambaSwitchSize'

export const LogoSwitch: FC = () => {
  const { partnerId, partner } = useShallowEqualSelector(
    ({ systemReducer: { partnerId, partner } }) => ({
      partnerId,
      partner,
    })
  )

  switch (partnerId) {
    case 3:
    case 81449119:
      return <LogoMambaSwitchSize />

    case 958134831:
    case 963463432:
      return <LogoImage logo={wamba} />

    case 132113273:
    case 475306:
      return null

    case 1374393173:
    case 1374393182:
      return <LogoImage logo={yonja} />
  }

  if (partner && partner.logoUrl) {
    return <LogoImage logo={partner.logoUrl} />
  }
}

const LogoImage: FC<{ logo: string }> = ({ logo }) => {
  return <Image src={logo} alt="logo" />
}

const Image = styled.img``
