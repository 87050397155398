import React, { SVGProps } from 'react'
export const BackSvg = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={64}
    height={64}
    viewBox="0 0 64 64"
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      d="M60.158 36.608c-9.229-5.768-19.89-8.33-29.295-9.255a92.036 92.036 0 0 0-15.658-.196l6.995-6.995a4 4 0 0 0-5.657-5.657L3.926 27.122a6.667 6.667 0 0 0 .408 9.803l15.12 12.795a4 4 0 1 0 5.168-6.107l-9.913-8.388c4.199-.377 9.566-.482 15.37.09 8.662.852 18.002 3.179 25.84 8.077a4 4 0 0 0 4.24-6.784Z"
    />
  </svg>
)
