import React, { SVGProps } from 'react'
export const SettingsSvg = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={64}
    height={64}
    viewBox="0 0 64 64"
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      d="M54.655 24.146c-5.066 0-7.137-3.616-4.618-8.052 1.455-2.57.588-5.848-1.959-7.317L43.236 5.98c-2.21-1.328-5.065-.537-6.38 1.695l-.308.537c-2.519 4.435-6.66 4.435-9.208 0l-.308-.537c-1.259-2.232-4.113-3.023-6.324-1.695l-4.842 2.797c-2.547 1.469-3.414 4.774-1.959 7.345 2.547 4.408.476 8.024-4.59 8.024-2.91 0-5.317 2.401-5.317 5.368v4.972c0 2.938 2.379 5.368 5.317 5.368 5.066 0 7.137 3.616 4.59 8.052-1.455 2.57-.588 5.848 1.96 7.317l4.84 2.797c2.212 1.328 5.066.537 6.381-1.695l.308-.537c2.519-4.435 6.661-4.435 9.208 0l.307.537c1.316 2.232 4.17 3.023 6.381 1.695l4.842-2.797c2.547-1.47 3.414-4.775 1.959-7.317-2.547-4.436-.476-8.052 4.59-8.052 2.91 0 5.317-2.401 5.317-5.368v-4.972c-.028-2.938-2.407-5.368-5.345-5.368ZM31.986 41.182c-5.01 0-9.095-4.125-9.095-9.182s4.086-9.182 9.095-9.182c5.01 0 9.095 4.125 9.095 9.182s-4.086 9.182-9.095 9.182Z"
    />
  </svg>
)
