import React, { FC } from 'react'

import { css, cx } from '@linaria/core'
import { styled } from '@linaria/react'

import { WithClassName } from 'common/types'

import { BaseImage } from '../BaseImage/BaseImage'
import { AvatarEmptySvg } from '../svgr/AvatarEmptySvg'

type Size = 16 | 24 | 32 | 40 | 48 | 64 | 80 | 96 | 128

export const Avatar: FC<
  {
    imageSource: string | null
    size?: Size
  } & WithClassName
> = ({ className, imageSource, size = 32 }) => {
  if (imageSource) {
    return (
      <BaseImage
        imageSource={imageSource}
        className={cx(className, imageSize[size])}
      />
    )
  }

  return (
    <ParentEmpty className={cx(className, imageSize[size])}>
      <AvatarEmpty width={size} height={size} />
    </ParentEmpty>
  )
}

const ParentEmpty = styled.div`
  background: var(--background-surface-2, #faf9f9);
`

const imageSize: Record<Size, string> = {
  16: css`
    width: 16px;
    height: 16px;
    border-radius: 6px;
  `,
  24: css`
    width: 24px;
    height: 24px;
    border-radius: 6px;
  `,
  32: css`
    width: 32px;
    height: 32px;
    border-radius: 12px;
  `,
  40: css`
    width: 40px;
    height: 40px;
    border-radius: 14px;
  `,
  48: css`
    width: 48px;
    height: 48px;
    border-radius: 16px;
  `,
  64: css`
    width: 64px;
    height: 64px;
    border-radius: 20px;
  `,
  80: css`
    width: 80px;
    height: 80px;
    border-radius: 24px;
  `,
  96: css`
    width: 96px;
    height: 96px;
    border-radius: 28px;
  `,
  128: css`
    width: 128px;
    height: 128px;
    border-radius: 40px;
  `,
}

const AvatarEmpty = styled(AvatarEmptySvg)`
  opacity: 0.32;
`
