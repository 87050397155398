import React, { SVGProps } from 'react'
export const LogoMambaSvg = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={560}
    height={121}
    fill="none"
    viewBox="0 0 560 121"
    {...props}
  >
    <path
      fill="#FF641F"
      d="M97.107 26.62c-10.133 0-21.11 2.699-31.243 7.583-8.272-5.22-17.058-7.246-26.349-7.246-12.837 0-26.349 3.875-39.515 8.768v82.925h35.632V54.598c4.726-1.681 11.313-2.194 11.313 4.212v59.832h35.632V54.599c4.894-1.682 11.313-2.363 11.313 4.211v59.832h35.8V57.633c0-22.076-14.691-31.013-32.591-31.013h.008ZM544.964 33.53c-9.796 0-15.365-6.91-31.917-6.91-28.034 0-42.556 18.538-42.556 47.188 0 28.651 14.522 46.684 42.556 46.684 8.609 0 22.121-2.699 31.917-2.699 5.913 0 10.976.169 15.028.841v-87.29c-4.052 1.346-9.123 2.195-15.028 2.195v-.008Zm-20.772 63.372c-2.03.673-3.884.84-5.408.84-8.272 0-9.628-8.255-9.628-22.925s1.516-23.261 9.796-23.261c1.517 0 3.21.336 5.232.84v44.498l.008.008ZM434.859 26.62c-5.745 0-12.5.673-20.098 4.717V.5H378.96v118.15c17.9-2.362 26.686 1.858 40.871 1.858 32.76 0 44.923-15.502 44.923-48.878 0-22.58-9.122-45.002-29.895-45.002v-.008Zm-14.691 71.123c-1.516 0-3.377-.168-5.407-.841V52.404c2.03-.504 3.714-.84 5.231-.84 8.272 0 9.796 8.255 9.796 23.261 0 15.006-1.347 22.926-9.628 22.926l.008-.008ZM179.684 26.62c-28.034 0-42.556 18.538-42.556 47.188 0 28.651 14.522 46.684 42.556 46.684 8.609 0 22.12-2.699 31.917-2.699 5.913 0 10.976.169 15.028.841v-87.29c-4.052 1.346-9.123 2.195-15.028 2.195-9.797 0-15.365-6.91-31.917-6.91v-.009Zm11.144 70.282c-2.03.673-3.883.84-5.408.84-8.272 0-9.628-8.255-9.628-22.925s1.517-23.261 9.797-23.261c1.516 0 3.209.336 5.231.84v44.498l.008.008ZM335.056 26.62c-10.133 0-21.109 2.699-31.243 7.583-8.272-5.22-17.057-7.246-26.348-7.246-12.838 0-26.349 3.875-39.516 8.768v82.925h35.632V54.598c4.726-1.681 11.313-2.194 11.313 4.212v59.832h35.632V54.599c4.894-1.682 11.313-2.363 11.313 4.211v59.832h35.8V57.633c0-22.076-14.69-31.013-32.591-31.013h.008Z"
    />
  </svg>
)
