import React, { FC, useCallback } from 'react'

import { useDispatch } from 'react-redux'

import { goBackOrRatingPathAction } from 'actions/route/routeAction'
import { CommonHandler } from 'common/types'
import { Colors } from 'common-constants/colors'
import { RouterLink } from 'components/designSystem/Link/RouterLink'
import { BackSvg as BackNextSvg } from 'components/designSystem/svgr/BackSvg'
import { ratingPath } from 'components/paths'
import { PlainLink } from 'components/presentational/link'
import { BackSvg } from 'components/presentational/svg/BackSvg'
import { useRedesignLayout } from 'hooks/useRedesign'

export const ModalBackButton: FC = () => {
  const dispatch = useDispatch()
  const redesignLayout = useRedesignLayout()

  const handleClick = useCallback<CommonHandler>(
    (event) => {
      event.preventDefault()
      dispatch(goBackOrRatingPathAction())
    },
    [dispatch]
  )

  if (redesignLayout) {
    return (
      <RouterLink
        onClick={handleClick}
        data-name="link-go-back-action"
        to={ratingPath}
        black={true}
      >
        <BackNextSvg width={24} height={24} />
      </RouterLink>
    )
  }

  return (
    <PlainLink
      onClick={handleClick}
      data-name="link-go-back-action"
      href={ratingPath}
    >
      <BackSvg color={Colors.link} />
    </PlainLink>
  )
}
