import React, { FC } from 'react'

import { styled } from '@linaria/react'

import { LogoLink } from 'components/block/Logo/LogoLink'
import { NavigationSettings } from 'components/block/Navigation/NavigationSettings'
import { breakpoints } from 'components/designSystem/shared/breakpoints'

export const NavigationBar: FC = () => {
  return (
    <Parent>
      <NavigationSettings />
      <ParentLogo>
        <LogoLink />
      </ParentLogo>
    </Parent>
  )
}

const Parent = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  padding: 0 var(--spacing-24px, 24px);
  height: 100%;

  @media screen and (max-width: ${breakpoints.tablet}px) {
    padding: 0 var(--spacing-16px, 16px);
    grid-template-columns: 1fr;
  }
`

const ParentLogo = styled.div`
  display: grid;
  justify-content: flex-end;

  @media screen and (max-width: ${breakpoints.tablet}px) {
    display: none;
  }
`
