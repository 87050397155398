import React, { SVGProps } from 'react'

export const MagnifierSvg = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={64}
    height={64}
    viewBox="0 0 64 64"
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M41.988 52.083a25.468 25.468 0 0 1-13.814 4.044c-14.088 0-25.508-11.37-25.508-25.397 0-14.026 11.42-25.397 25.508-25.397 14.087 0 25.507 11.37 25.507 25.397 0 3.582-.745 6.99-2.088 10.08l9.315 13.933c.252.42.417.967.425 1.384.024 1.367-1.155 2.548-2.55 2.54a2.739 2.739 0 0 1-1.203-.305l-15.592-6.279ZM15.42 30.73c0 7.013 5.71 12.698 12.754 12.698 7.043 0 12.753-5.685 12.753-12.698 0-7.013-5.71-12.698-12.753-12.698-7.044 0-12.754 5.685-12.754 12.698Z"
      clipRule="evenodd"
    />
  </svg>
)
