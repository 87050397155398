import React, { SVGProps } from 'react'

export const CardsSvg = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={64}
    height={64}
    viewBox="0 0 64 64"
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      d="M39.177 5.675c-5.65-1.471-11.5 1.944-13.067 7.627l-7.054 25.584c-1.568 5.683 1.742 11.483 7.392 12.954l14.662 3.818c5.65 1.471 11.5-1.943 13.067-7.627l6.743-25.415c1.567-5.683-1.431-11.652-7.081-13.123L39.177 5.675Z"
    />
    <path
      fill="currentColor"
      d="m10.685 17.821 6.838-1.768c1.373-.355 2.622.892 2.27 2.267l-5.44 21.295c-1.481 7.284 2.877 13.06 8.932 15.642.961.41.965 2.356-.053 2.598l-2.168.515A10.636 10.636 0 0 1 8.32 50.76L3.031 30.928c-1.529-5.733 1.91-11.622 7.654-13.107Z"
    />
  </svg>
)
