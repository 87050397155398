import React, {
  FC,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'

import { styled } from 'styled-components'

import { ContactSideBarLoadable } from 'components/page/ContactList/ContactSideBarLoadable'
import { LandingLeftBlockLoadable } from 'components/page/Landing/LandingLeftBlock/LandingLeftBlockLoadable'
import { sizes } from 'components/presentational'
import { zIndex } from 'components/presentational/zIndex'
import { isShowLeftLayout } from 'functions/isShowLeftLayout'
import { resolveHeaderHeight } from 'functions/resolveHeaderHeight'
import { useLayoutContext } from 'hooks/useLayoutContext'
import { useShallowEqualSelector } from 'hooks/useShallowEqualSelector'

import { photoLineRealHeight } from './MambaLayout.constants'
import { LayoutContext } from './MambaLayoutIndex.context'

const checkVerticalScroll = () =>
  document.documentElement.offsetHeight > window.innerHeight

export const SideBar: FC = () => {
  const { secondary: hasSecondary } = useLayoutContext()

  const {
    isLandingPage,
    partnerId,
    selectedProfileId,
  } = useShallowEqualSelector(
    ({
      systemReducer: { isLandingPage, partnerId },
      chatMessengerReducer,
    }) => ({
      isLandingPage,
      partnerId,
      /** chatMessengerReducer может быть не вставлен, но это не критично */
      selectedProfileId: chatMessengerReducer?.selectedProfileId,
    })
  )

  const { baseUrl } = useLayoutContext()
  const [showSecondaryLayout, setShowSecondaryLayout] = useState(
    isShowLeftLayout()
  )

  const containerRef = useRef<HTMLDivElement | null>(null)
  const { photolineVisible } = useContext(LayoutContext)
  const [stickyDelta, setStickyDelta] = useState(0)

  useEffect(() => {
    const handleResize = () => setShowSecondaryLayout(isShowLeftLayout())
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const withHiddenContainer = useCallback((calculation: () => boolean) => {
    /**
     * В методе хотим узнать, есть ли вертикальный скролл на странице.
     * Для этого надо скрыть левую колонку (контакты), который заранее может быть больше по высоте,
     * после предыдущих вычислений для страницы поиска (с горизонтальным скроллом).
     * Таким образом всегда порождать вертикальный скролл, если не скрыть.
     */
    containerRef.current!.style.display = 'none'
    const result = calculation()

    requestAnimationFrame(() => {
      if (containerRef.current) {
        containerRef.current.style.display = 'flex'
      }
    })

    return result
  }, [])

  const updateStickyDelta = useCallback(
    (hideSecondaryContainer?: boolean) => {
      const hasVerticalScroll = hideSecondaryContainer
        ? withHiddenContainer(checkVerticalScroll)
        : checkVerticalScroll()

      if (hasVerticalScroll || !photolineVisible) {
        setStickyDelta(0)
      } else {
        setStickyDelta(photoLineRealHeight)
      }
    },
    [photolineVisible, withHiddenContainer]
  )

  useEffect(() => {
    const handleScroll = () => {
      updateStickyDelta(false)
    }

    if (showSecondaryLayout) {
      window.addEventListener('scroll', handleScroll)
    }
    return () => window.removeEventListener('scroll', handleScroll)
  }, [photolineVisible, showSecondaryLayout, updateStickyDelta])

  useEffect(() => {
    if (showSecondaryLayout) {
      setStickyDelta(photolineVisible ? photoLineRealHeight : 0)
    }
  }, [baseUrl, photolineVisible, showSecondaryLayout])

  /**
   * Реакция на выбор контакта с переходом в сообщения.
   * https://youtrack.mamba.ru/issue/M-6398
   *
   * Так как переход может быть со страницы со скроллом,
   * надо пересчитать высоту без левого блока (контактов).
   */
  useEffect(() => {
    updateStickyDelta(true)
  }, [selectedProfileId, updateStickyDelta])

  return (
    <SecondaryWrapper
      $headerHeight={resolveHeaderHeight(partnerId)}
      $stickyDelta={stickyDelta}
      ref={containerRef}
    >
      {isLandingPage && <LandingLeftBlockLoadable />}
      {hasSecondary && showSecondaryLayout && <ContactSideBarLoadable />}
    </SecondaryWrapper>
  )
}

const SecondaryWrapper = styled.div<{
  $headerHeight: number
  $stickyDelta: number
}>`
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  flex-direction: column;
  flex-grow: 1;
  z-index: 1;

  @media (min-width: ${sizes.tabletLarge + 1}px) {
    position: sticky;
    top: ${(props) => props.$headerHeight}px;
    bottom: 0;
    max-height: calc(
      100vh - ${(props) => props.$headerHeight + props.$stickyDelta}px
    );

    z-index: ${zIndex.secondaryWrapper};
  }
`
