import React from 'react'

import { styled } from '@linaria/react'
import { FormattedMessage } from 'react-intl'
import { useLocation } from 'react-router'

import { CreateProfileOrLoginBlockInner } from 'components/block/Navigation/CreateProfileOrLoginBlockInner'
import { BlogLink } from 'components/designSystem/BlogLink/BlogLink'
import { SecondaryButton } from 'components/designSystem/Button/SecondaryButton'
import { ContactSideBarLoadable } from 'components/page/ContactList/ContactSideBarLoadable'
import { contactListPath, logoutPath } from 'components/paths'
import { useHasUserRestrictions } from 'functions/hasUserRestrictions'
import { mergeAllUrls } from 'functions/mergeAllUrls'
import { useAuthorized } from 'hooks/useAuthorized'
import { useLayoutContext } from 'hooks/useLayoutContext'

export const SidebarSwitch = () => {
  const authorized = useAuthorized()
  const { pathname } = useLocation()
  const hasRestrictions = useHasUserRestrictions()
  const { baseUrl } = useLayoutContext()

  if (!authorized) {
    return (
      <ParentSimple>
        <CreateProfileOrLoginBlockInner variant="block" />
        <BlogLink />
      </ParentSimple>
    )
  }

  if (hasRestrictions) {
    return (
      <ParentSimple>
        <SecondaryButton
          to={mergeAllUrls(baseUrl, logoutPath)}
          fullWidth={true}
          size="M"
          data-name="link-logout-action"
        >
          <FormattedMessage id="form.button.logout" defaultMessage="Выйти" />
        </SecondaryButton>
      </ParentSimple>
    )
  }

  if (authorized && !pathname.startsWith(contactListPath)) {
    return (
      <ParentContact>
        <ParentContactList>
          <ContactSideBarLoadable />
        </ParentContactList>
        <BlogLink />
      </ParentContact>
    )
  }

  return null
}

const ParentContact = styled.div`
  display: grid;
  grid-template-rows: 1fr auto;
  gap: var(--spacing-16px, 16px);
  grid-gap: var(--spacing-16px, 16px);
  position: sticky;
  top: 145px;
  min-width: 100%;
  height: calc(100vh - 165px);
`

const ParentContactList = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;
`

const ParentSimple = styled.div`
  display: grid;
  grid-auto-flow: row;
  height: fit-content;
  width: 100%;
  gap: var(--spacing-32px, 32px);
  grid-gap: var(--spacing-32px, 32px);
`
