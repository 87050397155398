import React, { FC } from 'react'

import { css, cx } from '@linaria/core'
import { styled } from '@linaria/react'
import { FormattedMessage } from 'react-intl'

import { PrimaryButton } from 'components/designSystem/Button/PrimaryButton'
import { SecondaryButton } from 'components/designSystem/Button/SecondaryButton'
import { selectGenderPath } from 'components/page/Onboarding/paths'
import { indexPath } from 'components/paths'
import { mergeAllUrls } from 'functions/mergeAllUrls'
import { useLocale } from 'hooks/useLocale'

export const CreateProfileOrLoginBlockInner: FC<{
  variant?: 'inline' | 'block'
}> = ({ variant = 'block' }) => {
  const locale = useLocale()

  return (
    <Parent className={cx(variantCss[variant])}>
      <PrimaryButton
        to={mergeAllUrls(locale, selectGenderPath)}
        size="M"
        data-name="create-profile-action"
        fullWidth={true}
      >
        <FormattedMessage
          id="app.create.profile"
          defaultMessage="Создать профиль"
        />
      </PrimaryButton>

      <SecondaryButton
        to={mergeAllUrls(indexPath)}
        fullWidth={true}
        size="M"
        data-name="login-action"
      >
        <FormattedMessage id="app.enter" defaultMessage="Войти" />
      </SecondaryButton>
    </Parent>
  )
}

const Parent = styled.div`
  display: grid;
  gap: var(--spacing-12px, 12px);
  grid-gap: var(--spacing-12px, 12px);
`

const variantCss: Record<string, ReturnType<typeof css>> = {
  inline: css`
    grid-auto-flow: column;
    align-items: center;
    grid-template-columns: max-content;
    max-width: 440px;
    width: 100%;
  `,

  block: css`
    grid-auto-flow: row;
    height: fit-content;
    width: 100%;
  `,
}
