import React from 'react'

import { css, cx } from '@linaria/core'
import { styled } from '@linaria/react'

import { breakpoints } from 'components/designSystem/shared/breakpoints'
import { Container } from 'components/designSystem/shared/container'
import { useHasUserRestrictions } from 'functions/hasUserRestrictions'
import { useAuthorized } from 'hooks/useAuthorized'

import { navigationHeight } from './constants'
import { TabBar } from './TabBar'
import { StickyObserver } from '../StickyObserver/StickyObserver'

export const Footer = () => {
  const userHasRestrictions = useHasUserRestrictions()
  const authorized = useAuthorized()

  if (userHasRestrictions) {
    return null
  }

  return (
    <FooterTab
      className={cx(!authorized && mobileNotAuthorizedCss)}
      position="bottom"
    >
      <TabBarInner isMobileBar={true} data-name="tab-bar-mobile" />
    </FooterTab>
  )
}

const FooterTab = styled(StickyObserver)`
  display: none;
  justify-content: center;
  align-items: center;
  position: sticky;
  z-index: 2;
  bottom: 0;

  min-width: 100%;

  @media screen and (max-width: ${breakpoints.middleDesktop}px) {
    display: flex;
  }
`

const TabBarInner = styled(TabBar)`
  position: relative;
  flex-basis: 100%;
  z-index: 2;
  height: ${navigationHeight * 2}px;

  @media screen and (max-width: ${breakpoints.middleDesktop}px) {
    flex-basis: ${Container.tablet}px;
  }

  @media screen and (max-width: ${breakpoints.tablet}px) {
    height: ${navigationHeight}px;
  }
`

const mobileNotAuthorizedCss = css`
  @media screen and (max-width: ${breakpoints.tablet}px) {
    height: 56px;
    padding: var(--spacing-12px, 12px) 0;
    box-sizing: content-box;
    min-width: calc(100% - var(--spacing-12px) * 2);
  }
`
