import React from 'react'

import { RouterLink } from 'components/designSystem/Link/RouterLink'
import { indexPath } from 'components/paths'

import { LogoSwitch } from './LogoSwitch'

export const LogoLink = () => {
  return (
    <RouterLink to={indexPath}>
      <LogoSwitch />
    </RouterLink>
  )
}
